<template>
  <b-card>
    <div class="custom-container">
      <form @submit.prevent="sendData">
        <div class="row">
          <div class="col-12">
            <card>
              <template v-slot:headerTitle>
                <vs-button
                  size="large"
                  transparent
                  class="m-0"
                  to="/helex/perfil/medallas"
                >
                  <i class="fas fa-chevron-left mr-1"></i> Volver a mis medallas
                </vs-button>
                <h2 class="card-title"><b>Enviar Medalla</b></h2>
              </template>
              <div class="row mt-4">
                <div class="col-12 col-lg">
                  <!-- usuario -->
                  <div class="mb-3">
                    <label for="usuario" class="form-label">
                      <em class="text-danger font-weight-bolder">Usuario:</em>
                    </label>
                    <!-- vue-select -->
                    <div
                      style="
                        background: transparent;
                        padding: 0rem;
                       
                      "
                    >
                      <v-select
                        v-model="medalla.personaId"
                        class="style-chooser"
                        :reduce="(value) => value.id"
                        :options="listaUsuarios"
                        :get-option-label="
                          (option) => `${option.nombres} ${option.apellidos}`
                        "
                        placeholder="-- seleccionar persona --"
                      >
                        <!-- required -->
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!medalla.personaId"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <!-- sin resultados en la busquedad -->
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No se encontraron resultados para
                            <em class="text-danger">{{ search }}</em
                            >.
                          </template>
                          <em v-else style="opacity: 0.5"
                            >No hay personas para mostrar.</em
                          >
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- medalla -->
                  <div class="mb-3">
                    <label for="medalla" class="form-label">
                      <em class="text-danger font-weight-bolder">Medalla:</em>
                    </label>
                    <!-- vue-select -->
                    <div
                      style="
                        background: transparent;
                        padding: 0rem;
                       
                      "
                    >
                      <v-select
                        v-model="medalla.medallasId"
                        class="style-chooser"
                        :reduce="(value) => value.id"
                        :options="listaMedallas"
                        :get-option-label="(option) => `${option.descripcion}`"
                        placeholder="-- seleccionar medalla --"
                      >
                        <!--  agregar contenido adicional a las opciones-->
                        <template v-slot:option="option">
                          <img
                            class="rounded"
                            width="50"
                            :src="`${option.imagen}`"
                            alt=""
                          />
                          {{ option.descripcion }}
                        </template>
                        <!-- required -->
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!medalla.medallasId"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <!-- sin resultados en la busquedad -->
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No se encontraron resultados para
                            <em class="text-danger">{{ search }}</em
                            >.
                          </template>
                          <em v-else style="opacity: 0.5"
                            >No hay medallas para mostrar.</em
                          >
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
                <!-- comentario -->
                <div class="col-12 col-lg">
                  <label for="comentario" class="form-label">
                    <em class="text-danger font-weight-bolder">Comentario:</em>
                  </label>
                  <b-form-textarea
                    style="resize: none"
                    v-model="medalla.comentario"
                    placeholder="Aquí puedes agregar un comentario..."
                    rows="7"
                    minlength="10"
                    maxlength="255"
                  ></b-form-textarea>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <vs-button animation-type="vertical"   size="large">
                    Enviar Medalla
                    <template #animate>
                      <i class="fas fa-paper-plane"></i
                    ></template>
                  </vs-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </form>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  name: "Data Table",
  data() {
    return {
      listaMedallas: [],
      listaUsuarios: [],
      usuario: {},
      medalla: {
        personaId: null,
        medallasId: null,
        emisorId: null,
        comentario: null,
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.medalla.emisorId = this.usuario.id;
    await this.getListPersona(this.usuario.empresa.id);
    this.getListMedallas();
    this.$isLoading(false);
  },
  methods: {
    async sendData() {
      this.medalla.fechaObtencion = this.dateNow();
      console.log(this.medalla);
      await this.postMedallasPersonaCreate(this.medalla);
    },
    dateNow() {
      var fechaActual = new Date();
      var año = fechaActual.getFullYear();
      var mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
      var dia = fechaActual.getDate().toString().padStart(2, "0");

      var fechaFormateada = año + "-" + mes + "-" + dia;
      return fechaFormateada;
    },
    async getListPersona(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + empresaId,
        });
        console.log("list persona... ", res);
        if (res.length > 0) {
          // Filtrar el array para excluir el usuario con el ID especificado
          let usuariosFiltrados = res.filter(
            (usuario) => usuario.id !== this.usuario.id
          );

          this.listaUsuarios = usuariosFiltrados;
        } else {
          this.listaUsuarios = [];
        }
      } catch (error) {
        this.listaUsuarios = [];
        console.log("err", error);
      }
    },
    async getListMedallas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Medallas/List/",
        });
        console.log("list medallas... ", res);
        if (res.length > 0) {
          this.listaMedallas = res;
        } else {
          this.listaMedallas = [];
        }
      } catch (error) {
        this.listaMedallas = [];
        console.log("err", error);
      }
    },
    async postMedallasPersonaCreate(medallaPersona) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "MedallasPersona/Create/",
          data: medallaPersona,
        });

        console.log("id medallaPersona... ", res);
        Swal.fire("Listo!", "Medalla enviada.", "success");
        this.medalla.personaId = this.textDefaulUsuario;
        this.medalla.medallasId = this.textDefaulMedalla;
        this.medalla.comentario = null;
      } catch (error) {
        console.log("err", error);
        Swal.fire(
          "Error!",
          "Ha ocurrido un error al enviar la medalla.",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
